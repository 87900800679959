
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import InputCompanies from "@/components/InputCompanies.vue";
import CallInProgress from "./CallInProgress.vue";

import { mapActions, mapGetters, mapState } from "vuex";
import { rolColor } from "@/utils/rolesStatus";

export default Vue.extend({
  components: { InputCompanies, CallInProgress },
  name: "online-workers",
  data() {
    return {
      currentUi: 0,
      number: "",
      companyId: "",
      loading: false,
    };
  },
  computed: {
    ...mapState("crmCallCenterModule", ["onlineWorkers"]),
    ...mapState(["profile"]),
    ...mapGetters(["isSuper"]),
    // ...mapGetters("crmCallCenterModule", ["contactsOnline"]),
  },
  watch: {
    companyId(uuid: string): void {
      this.loading = true;
      this.actOnlineWorkers(uuid).then(() => {
        this.loading = false;
      });
    },
  },
  methods: {
    ...mapActions("crmCallCenterModule", ["actOnlineWorkers"]),
    makeCall(number: string): void {
      this.number = number;
      this.currentUi = 1;
    },
    rolColor,
  },
  mounted() {
    if (!this.isSuper) {
      this.companyId = this.profile.company.uuid;
    }
  },
});
